import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "S-Mount Lenses",
  "path": "/Products/Lenses/S-Mount/IN-0290/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IP Camera Lense Upgrades",
  "image": "../../../P_SearchThumb_Products.png",
  "social": "/images/Search/P_SearchThumb_Products.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-0290 S-Mount Lenses' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IP Camera Lense Upgrades' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Lenses/S-Mount/Lense_Comparison/IN-0290/' locationFR='/fr/Products/Lenses/S-Mount/Lense_Comparison/IN-0290/' crumbLabel="IN-0290" mdxType="BreadCrumbs" />
    <h2 {...{
      "id": "in-0290-wideangle",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0290-wideangle",
        "aria-label": "in 0290 wideangle permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0290 Wideangle`}</h2>
    <p><strong parentName="p">{`Compatible Camera Models`}</strong></p>
    <ul>
      <li parentName="ul">{`IN-3001 Indoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-3011/"
        }}>{`IN-3010/1 Indoor Camera`}</a></li>
      <li parentName="ul">{`IN-2904 Outdoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-2905_V2/"
        }}>{`IN-2905 V2 Outdoor Camera`}</a></li>
    </ul>
    <p><img parentName="p" {...{
        "src": "./Wideangle_Lense_2.9mm_-_Angle_of_View.png",
        "alt": "IN-0290 Wideangle"
      }}></img></p>
    <p>{`The angle of view of the 2.9mm wideangle lense. Field Angle (D x H x V): 82.4° x 66.1° x 50.9°.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/43810b1a88983f2a6414286c0b92b541/c08c5/Wideangle_2.2mm_IRcut.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAeVpuCqZP//EABgQAAMBAQAAAAAAAAAAAAAAAAACEgET/9oACAEBAAEFAlURdIcos66f/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BR//EABoQAAICAwAAAAAAAAAAAAAAAAABETEgM4H/2gAIAQEABj8CoqUa3x4f/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERQSExYYH/2gAIAQEAAT8hp10l7GY4peMWKVkPA0bsvwvBq0//2gAMAwEAAgADAAAAEBs//8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EJiP/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Qg//EABoQAQEBAQEBAQAAAAAAAAAAAAERACFhUXH/2gAIAQEAAT8QSGq6MGeCgAAh5unoVMgu/fc+FPRpMs/IZ6Ew3//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/43810b1a88983f2a6414286c0b92b541/e4706/Wideangle_2.2mm_IRcut.avif 230w", "/en/static/43810b1a88983f2a6414286c0b92b541/d1af7/Wideangle_2.2mm_IRcut.avif 460w", "/en/static/43810b1a88983f2a6414286c0b92b541/e6133/Wideangle_2.2mm_IRcut.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/43810b1a88983f2a6414286c0b92b541/a0b58/Wideangle_2.2mm_IRcut.webp 230w", "/en/static/43810b1a88983f2a6414286c0b92b541/bc10c/Wideangle_2.2mm_IRcut.webp 460w", "/en/static/43810b1a88983f2a6414286c0b92b541/0ba47/Wideangle_2.2mm_IRcut.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/43810b1a88983f2a6414286c0b92b541/e83b4/Wideangle_2.2mm_IRcut.jpg 230w", "/en/static/43810b1a88983f2a6414286c0b92b541/e41a8/Wideangle_2.2mm_IRcut.jpg 460w", "/en/static/43810b1a88983f2a6414286c0b92b541/c08c5/Wideangle_2.2mm_IRcut.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/43810b1a88983f2a6414286c0b92b541/c08c5/Wideangle_2.2mm_IRcut.jpg",
              "alt": "IN-0290 Wideangle",
              "title": "IN-0290 Wideangle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Test image of the 2.9mm wideangle lense on a 1/5" CMOS sensor with IRcut filter.`}</p>
    <h2 {...{
      "id": "compare-all-lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#compare-all-lenses",
        "aria-label": "compare all lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compare All Lenses`}</h2>
    <Link to="/Products/Lenses/S-Mount/Objektiv_Vergleich/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Overview</EuiButton></Link>
    <h2 {...{
      "id": "in-0290-wideangle-lense-chart",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-0290-wideangle-lense-chart",
        "aria-label": "in 0290 wideangle lense chart permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-0290 Wideangle Lense Chart`}</h2>
    <p>{`Lense chart for the 2.9mm wideangle for the 1/4" CMOS Sensor (Width/Hight vs. Distance from the Object).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "724px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/06341ddbb52ed0a03d367ab71678bddc/a242d/Lense_Chart_2_9mm.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABy0lEQVQoz3VSu2obQRTdT3RIEcedIU0gD5PaSRGMId8SVUqwqiSdXUllQKzW8VqLtNK8JO28n2HnmrUh5BTDwMy558EtrLUxRuec9y6fvlkuq6oqy7JpmqqqFovFfD6v61prHWOKMYYQfEZhrS0X5e1taa1JKYXgdzumlDTGeO+ttcYYpaSzLqUkleCce+9jRsG5OH3z+tXp0a+fP/b7A2PsavJttWpS6kXgzAiEYoS3+wzQL7TWo+/jz18up9MpY7TjndbKGAVMAOeHdrOWUsIYmNiThRAhhOEfiMQYffCQAhNEKEkpaSMQXnPOnXMPZCmlcy6ETMhhBqtSCkyQlEKqbosbTFtrDeec7dgjeVCGESkl7z2lhDLScUZ3LaEb5xwY+a9tuGitEN4gsqaspQxlk+np9IcCQyi6rvPew1svyEizutugJWVbUIN/cHmaqyfDkqSUlBJ39+X9sjx0FFrIBT6Shy4GFwXs1qqtqz+/Cd1Cyf8SQgiz2ezraHR29uH846e3795fX9/0ZETaeln5fj/7pYPlM8YMWZxzxpjxeHxxcXn07PmL45PjlydXk0mhlILMMUat9VAJYwxlYIyHaFpray3MEkL8BTs/3HmxVLXCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06341ddbb52ed0a03d367ab71678bddc/e4706/Lense_Chart_2_9mm.avif 230w", "/en/static/06341ddbb52ed0a03d367ab71678bddc/d1af7/Lense_Chart_2_9mm.avif 460w", "/en/static/06341ddbb52ed0a03d367ab71678bddc/671c7/Lense_Chart_2_9mm.avif 724w"],
              "sizes": "(max-width: 724px) 100vw, 724px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/06341ddbb52ed0a03d367ab71678bddc/a0b58/Lense_Chart_2_9mm.webp 230w", "/en/static/06341ddbb52ed0a03d367ab71678bddc/bc10c/Lense_Chart_2_9mm.webp 460w", "/en/static/06341ddbb52ed0a03d367ab71678bddc/79561/Lense_Chart_2_9mm.webp 724w"],
              "sizes": "(max-width: 724px) 100vw, 724px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/06341ddbb52ed0a03d367ab71678bddc/81c8e/Lense_Chart_2_9mm.png 230w", "/en/static/06341ddbb52ed0a03d367ab71678bddc/08a84/Lense_Chart_2_9mm.png 460w", "/en/static/06341ddbb52ed0a03d367ab71678bddc/a242d/Lense_Chart_2_9mm.png 724w"],
              "sizes": "(max-width: 724px) 100vw, 724px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/06341ddbb52ed0a03d367ab71678bddc/a242d/Lense_Chart_2_9mm.png",
              "alt": "IN-0290 Wideangle",
              "title": "IN-0290 Wideangle",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sensing Area:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/5" CMOS; 5,08mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Mount:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S-Mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focal Length:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.9 mm (f)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Back Focal Lenght:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.02 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Aperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Iris:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optical Distortion:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`<   -29%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Construction:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 Glasses (+IR Filter)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focus Extent:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20cm - ∞`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Angle (D x H x V):`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`82.4° x 66.1° x 50.9°`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Weight:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6.0 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Temperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-10°C - 50°C`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      